body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background: #f8f8f8;
}

.select-css {
  display: block;
  width: 300px;
  height: 45px;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: .6em 1.4em .5em .8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0 15px 0 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
	border-radius: .5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
	  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
	display: none;
}
.select-css:hover {
	border-color: #888;
}
.select-css:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.select-css option {
	font-weight:normal;
}


.btn-primary, .btn-primary:active:focus, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #5D0E4E !important;
  border-color: #5D0E4E !important;
  box-shadow: none;
  color: white;
  max-width: 98%;
}

.category-label {
  margin-bottom: 5px;
}

.finish-button {
  background-color: rgb(84, 135, 212) !important;
}
.finish-button:hover {
  background-color: rgb(68, 118, 194) !important;
}

.finish-button:active:focus {
  background-color: rgb(68, 118, 194) !important;
}

.button-size {
  min-width: 150px; 
  margin: 5px 10px;
}

@media all and (max-width: 760px) {
  .button-size {
    margin: 5px 0;
  }
}

.category-div { 
  display: flex; 
  flex-direction: row;
  justify-content: space-between; 
  padding-bottom: 15px;
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}

@media only screen and (max-width: 760px) {
  .category-div {
    flex-direction: column;
  }


  .select-css {
    width: 100%;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
}